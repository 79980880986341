import React, { Component } from 'react';
import axios from 'axios';
import {
  Card,
  CardHeader,
  Progress,
  Container,
  Row,
  CardBody
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "components/Headers/HeaderDataless.js";

import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'


class Upload extends Component {

  // const [url, getUrl] = useState('');
  
  constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        loaded:0,
        finalurl: 'No URL Yet'
      }
   
  }
  
  checkMimeType=(event)=>{
    //getting file object
    let files = event.target.files 
    //define message container
    let err = []
    // list allow mime type
   const types = ['']
    // loop access array
    for(var x = 0; x<files.length; x++) {
     // compare file type find doesn't matach
         if (types.every(type => files[x].type !== type)) {
         // create error message and assign to container   
         err[x] = files[x].type+' is not a supported format\n';
       }
     };
     for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
         // discard selected file
        toast.error(err[z])
        event.target.value = null
    }
   return true;
  }
  maxSelectFile=(event)=>{
    let files = event.target.files
        if (files.length > 3) { 
           const msg = 'Only 3 images can be uploaded at a time'
           event.target.value = null
           toast.warn(msg)
           return false;
      }
    return true;
 }
 checkFileSize=(event)=>{
  let files = event.target.files
  let size = 2000000 
  let err = []; 
  for(var x = 0; x<files.length; x++) {
  if (files[x].size > size) {
   err[x] = files[x].type+'is too large, please pick a smaller file\n';
 }
};
for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
  // discard selected file
 toast.error(err[z])
 event.target.value = null
}
return true;
}
onChangeHandler=event=>{
  
  var files = event.target.files
  if(this.maxSelectFile(event) && this.checkMimeType(event) &&    this.checkFileSize(event)){ 
  // if return true allow to setState
     this.setState({
     selectedFile: files,
     loaded:0
  })

}
}
  onClickHandler = () => {
    const data = new FormData() 
    for(var x = 0; x<this.state.selectedFile.length; x++) {
      data.append('file', this.state.selectedFile[x])
    }
    axios.post("https://ecardsapi.ethancottrill.com/upload", data, {

      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
        })
      },
    })
 

      .then(res => { // then print response status
        toast.success('eCard uploaded sucessfully to server')
        this.setState({ finalurl: res.data });
        
      })
      .catch(err => { // then print response status
        toast.error('upload fail')
      })
 



    }

    


  render() {
    return (
      <>
      
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Upload</h3>
              </CardHeader>
              
              <CardBody>
              
                <Row className="icon-examples">
                
      	  <div class="offset-md-3 col-md-6">
            
            
          <Protect 
          sha512='4F803A488D561F0EB2F702D2B25AFFC298AF1978092086006D7AEAC60FBD8BE3EB9BD85153801EC4DB5F370257A1C8BF9122A934AAA2BB162CC2F734BE90800B' 
          boxTitle='Enter password for user Sandra Cottrill'
          styles={{
            header: { fontSize: '20px' }
          }}
          >
               <div class="form-group files">
                <center><label>Upload Compiled eCard Data File </label></center><br></br>
                
                <input type="file" class="form-control" enctype="multipart/form-data" multiple onChange={this.onChangeHandler}/>
  
              </div>  
       
              <div class="form-group">
              <ToastContainer />
              <Progress max="100" color="success" value={this.state.loaded} >{Math.round(this.state.loaded,2) }%</Progress>
              <p>Click below to redirect to new eCard!</p>
              <a href={this.state.finalurl} target="_blank" rel="noreferrer">{this.state.finalurl}</a>
              
        
              </div> 

              <button type="button" class="btn btn-success btn-block" onClick={this.onClickHandler}>Upload</button>
       
              </Protect>
	      </div>


                </Row>
            
              </CardBody>
         
            </Card>
          </div>
        </Row>
      </Container>
    </>
    );
  }
}

export default Upload;