import React, { Component } from 'react';
import axios from 'axios';
import {
  Card,
  CardHeader,
  Progress,
  Container,
  Row,
  CardBody
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "components/Headers/HeaderDataless.js";

import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'


class Gen extends Component {

  // const [url, getUrl] = useState('');
  
  constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        loaded:0,
        finalurl: 'No URL Yet'
      }
   
  }
  
  checkMimeType=(event)=>{
    //getting file object
    let files = event.target.files 
    //define message container
    let err = []
    // list allow mime type
   const types = ['']
    // loop access array
    for(var x = 0; x<files.length; x++) {
     // compare file type find doesn't matach
         if (types.every(type => files[x].type !== type)) {
         // create error message and assign to container   
         err[x] = files[x].type+' is not a supported format\n';
       }
     };
     for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
         // discard selected file
        toast.error(err[z])
        event.target.value = null
    }
   return true;
  }
  maxSelectFile=(event)=>{
    let files = event.target.files
        if (files.length > 3) { 
           const msg = 'Only 3 images can be uploaded at a time'
           event.target.value = null
           toast.warn(msg)
           return false;
      }
    return true;
 }
 checkFileSize=(event)=>{
  let files = event.target.files
  let size = 2000000 
  let err = []; 
  for(var x = 0; x<files.length; x++) {
  if (files[x].size > size) {
   err[x] = files[x].type+'is too large, please pick a smaller file\n';
 }
};
for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
  // discard selected file
 toast.error(err[z])
 event.target.value = null
}
return true;
}
onChangeHandler=event=>{
  
 
}




    


  render() {
    return (
      <>
      
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Generator</h3>
              </CardHeader>
              
              <CardBody>
              
                <Row className="icon-examples">
                
      	  <div class="offset-md-3 col-md-6">
            
            
          <Protect 
          sha512='4F803A488D561F0EB2F702D2B25AFFC298AF1978092086006D7AEAC60FBD8BE3EB9BD85153801EC4DB5F370257A1C8BF9122A934AAA2BB162CC2F734BE90800B' 
          boxTitle='Enter password for user Sandra Cottrill'
          styles={{
            header: { fontSize: '20px' }
          }}
          >
               

              <a class="btn btn-success btn-block" href="https://gen.ethancottrill.com" target="_blank">Open eCard Design software in New Tab</a>
              <a class="btn btn-success btn-block" href="https://bibekkakati.github.io/qr-gen/" target="_blank">Open QR Code Creator in New Tab</a>
       
              </Protect>
	      </div>


                </Row>
            
              </CardBody>
         
            </Card>
          </div>
        </Row>
      </Container>
    </>
    );
  }
}

export default Gen;