import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function JsonDataDisplay(){
    const [data, getData] = useState([])
    const URL = "https://ecardsapi.ethancottrill.com/list"

    
    
   

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = () => {
        fetch(URL)
            .then((res) =>
                res.json())
 
            .then((response) => {
                
                getData(response);
            })
 
    }

    function sendPause(idrequest) {

       
    fetch('https://ecardsapi.ethancottrill.com/pause', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        LXUpRHlkX0ssU1V7a05kL3YyUT46dFxIeA: idrequest
        })
    }).then(window.location.reload(false))
    
}


       
  
 
    return(
        <div>
            <table class="table table-striped">
                <thead>
                    <tr>
                    <th>Name on File</th>
                    <th>Date of Renewal</th>
                    <th>Link To Card</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                 
                    
                {data.map((item, i) => (
                    <tr key={i}>
                        <td>{item.ecard_name}</td>
                    <td>{item.date}</td>
                    <td><a href={item.link} target="_blank" rel="noreferrer"><button  class="btn btn-secondary btn-block btn-info btn-sm" >Open Card</button></a></td>
                    <td><a  onClick={() => sendPause(item.idrequest)} target="_blank" rel="noreferrer"><button  class="btn btn-secondary btn-block btn-dark btn-sm" >Pause Card</button></a></td>
                    </tr>
                ))}
                    
                </tbody>
            </table>
             
        </div>
    )
 }
 
 export default JsonDataDisplay;