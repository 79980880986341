
import Index from "views/Index.js";
import Gen from "views/examples/Gen.js";
import Upload from "views/examples/Upload.js";
import Control from "views/examples/Control"

var routes_sidebar = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  /*{
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
  },
  
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },*/
  /*{
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
  },*/

  /*{
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },*/
  {
    path: "/gen",
    name: "Generator",
    icon: "fas fa-cogs text-purple",
    component: Gen,
    layout: "/admin",
  },
  {
    path: "/upload",
    name: "Upload",
    icon: "lni lni-cloud-upload text-purple",
    component: Upload,
    layout: "/admin",
  },
  {
    path: "/control",
    name: "Control Panel",
    icon: "lni lni-control-panel text-purple",
    component: Control,
    layout: "/admin",
  }
];
export default routes_sidebar;
