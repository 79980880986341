import React, { Component } from 'react';
import axios from 'axios';
import {
  Card,
  CardHeader,
  Progress,
  Container,
  Row,
  CardBody
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "components/Headers/HeaderDataless.js";

import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'
import JsonDataDisplay from 'json_render';
import JsonDataDisplayPause from 'json_render_paused';
import { Link } from 'react-router-dom';


class ControlPaused extends Component {

  // const [url, getUrl] = useState('');
  
  constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        loaded:0,
        finalurl: 'No URL Yet'
      }
   
  }
  
  

  render() {
    return (
      <>
      
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
       {/* Table */}
       <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Control (Paused View)</h3>
              </CardHeader>
              
              <CardBody>
              
                <Row className="icon-examples">
                <Protect 
          sha512='4F803A488D561F0EB2F702D2B25AFFC298AF1978092086006D7AEAC60FBD8BE3EB9BD85153801EC4DB5F370257A1C8BF9122A934AAA2BB162CC2F734BE90800B' 
          boxTitle='Enter password for user Sandra Cottrill'
          styles={{
            header: { fontSize: '20px' }
          }}
          >
                
      	  <div class="offset-md-3 col-md-6">
            
            
          
               <div class="form-group files">
                <center><label>Control for eCard Database</label></center><br></br>
                <Link to="/admin/control"> <button  class="btn btn-success btn-block" >Go to Active Cards</button></Link>
                
                </div>  
              

              

              
              
              </div>
              
              <div class="table table-bordered table-striped text-center"><JsonDataDisplayPause/></div>
              </Protect>
      
      
                      </Row>
                  
                    </CardBody>
               
                  </Card>
          </div>
        </Row>
      </Container>
    </>
    );
  }
}

export default ControlPaused;